/********************************************************************
 * SCROLL REVEAL
*********************************************************************/

// Check if element is completely in view
function isScrolledIntoView(elem)
{
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

// Check if element bottom in view
function bottomScrolledIntoView(elem, btmOffset) {
    var offset = 0;
    if(btmOffset == ''){
        offset = btmOffset;
    }

    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height() + offset;

    return ((elemBottom <= docViewBottom));
}

// Initial call with custom settings
window.sr = ScrollReveal({
    origin   : "right",
    distance : "700px",
    duration: 900,
    viewFactor: 1.0
});

// Common settings for scrollreveal animations
var summResultsHidden = true;
var mediaBuzzHidden = true;
var mobProductHidden = true;
var badgesSlide = {
    origin: "bottom",
    delay: 600,
    distance: "300px",
    afterReveal: function(){
        $('#winning-badges .first, #winning-badges .second, #winning-badges .third').addClass('pulse');
    }
};
var resultsSlide = { 
    delay: 100, 
    viewFactor: 0.8,
    viewOffset: {
        bottom: 80
    }
};
var leftSlide = {
    viewFactor: 0.4,
    origin : "left"
};


// Base left/right slides
sr.reveal('.slide-right', { viewFactor: 0.4 });
sr.reveal('.slide-left', leftSlide);

// Rewind Clock
sr.reveal('[data-rewind="sub1"]', {
    origin: "bottom",
    distance: "0px",
    duration: 1200,
    delay: 200
});
sr.reveal('[data-rewind="sub2"]', {
    origin: "bottom",
    distance: "0px",
    duration: 1200,
    delay: 600
});


if($(window).width() > 767){
    // DESKTOP ANIMATIONS
    // Real Women, Real Results Animations
    sr.reveal('[data-real="1"], [data-real="2"], [data-real="3"]', leftSlide);
    
} else{
    // MOBILE ANIMATIONS
    // Real Women, Real Results Animations
    sr.reveal('[data-real="1"]', leftSlide);
    sr.reveal('[data-real="2"]', { viewFactor: 0.8, origin : "left", delay: 200 });
    sr.reveal('[data-real="3"]', { viewFactor: 0.8, origin : "left", delay: 500 });

    // Top Banner Animations
    $('[data-topBanner="m1"]').delay(400).animate({ left: '0', opacity: 1 }, 400, 'swing');
    $('[data-topBanner="m2"]').delay(1000).animate({ left: '0', opacity: 1 }, 400, 'swing');
    $('[data-topBanner="m3"]').delay(1600).animate({ right: '-5.5vw', opacity: 1 }, 400, 'swing');
    $('[data-topBanner="m4"]').delay(2200).animate({ right: '-18vw', opacity: 1 }, 400, 'swing', function(){
        loadBarsMobile();
    });

    // Clinical Results Animations
    sr.reveal('[data-result="m1"], [data-result="m2"], [data-result="m3"], [data-result="m4"]', resultsSlide);
}


// On scroll checks
$(window).on('resize scroll', function() {
    if($(window).width() > 767){
        // DESKTOP
        // Media Buzz
        if(isScrolledIntoView($('.media-buzz')) && mediaBuzzHidden){
            sr.reveal('[data-buzz="d1"]');
            sr.reveal('[data-buzz="d2"]', { delay: 200 });
            sr.reveal('[data-buzz="d3"]', { delay: 400 });
            sr.reveal('#winning-badges', badgesSlide);
            mediaBuzzHidden = false;
        }

        // Summary of Results
        if(isScrolledIntoView($('.summ-results')) && summResultsHidden){
            sr.reveal('[data-result="d1"]');
            sr.reveal('[data-result="d2"]', { delay: 200 });
            sr.reveal('[data-result="d3"]', { delay: 400 });
            sr.reveal('[data-result="d4"]', { delay: 600 });
            summResultsHidden = false;
        }
    } else {
        // MOBILE
        // Media Buzz
        if(bottomScrolledIntoView($('[data-buzz="d1"]'), 100) && mediaBuzzHidden){
            sr.reveal('[data-buzz="d2"]');
            sr.reveal('[data-buzz="d3"]', { delay: 200 });
            sr.reveal('[data-buzz="d1"]', { delay: 400 });
            sr.reveal('#winning-badges', badgesSlide);
            mediaBuzzHidden = false;
        }

        // Product badges
        if(bottomScrolledIntoView($('.productImg')) && mobProductHidden){
            sr.reveal('[data-badge="1"]', { origin: "left" });
            sr.reveal('[data-badge="2"]', { origin: "left", delay: 200 });
            sr.reveal('[data-badge="3"]', { origin: "left", delay: 400 });
            mobProductHidden = false;
        }
    }
});
   



/********************************************************************
 * PERCENT BAR ANIMATIONS
*********************************************************************/
        
// Animating Percentage
function animateValue(numID, start, end, duration) {
    var range = end - start;
    var current = start;
    var increment = end > start? 1 : -1;
    var stepTime = Math.abs(Math.floor(duration / range));
    var obj = document.getElementById(numID);
    var timer = setInterval(function() {
        // If first percentage, increment by 2 since final number is so high.
        if(numID == 'n1' || numID == 'mobn1'){
            current += 2;
            obj.innerHTML = current;
            if(current > end){
                obj.innerHTML = end;
                clearInterval(timer);
            }
        } else {
            current += increment;
            obj.innerHTML = current;
            if (current == end) {
                clearInterval(timer);
            }
        }
    }, stepTime);
}

// Animating Bars
function fillBar(id, percentage, numID) {
    var elem = document.getElementById(id);
    var width = 0;
    var id = setInterval(frame, 10);
    function frame() {
        if (width >= 100) {
            clearInterval(id);
        } else {
            width++;
            elem.style.width = width + '%';
        }
    }
    animateValue(numID, 0, percentage, 1000);
}
    
// Runs functions to animate bars and percentages sequentially
function loadBars(){
    fillBar('bar1', 500, 'n1');
    setTimeout(function(){
        fillBar('bar2', 60, 'n2');
    }, 1000);
    setTimeout(function(){
        fillBar('bar3', 100, 'n3');
    }, 1800);
    setTimeout(function(){
        $('.red-arrow').css({'visibility':'visible'}).animate({left: '50px'},250, function(){
            $('.rush-my-order1 .btn-img').addClass('pulse');
        });
    }, 2600);
}
loadBars();

function loadBarsMobile() {
    fillBar('mobbar1', 500, 'mobn1');
    setTimeout(function() {
        fillBar('mobbar2', 60, 'mobn2');
    }, 1000);
    setTimeout(function() {
        fillBar('mobbar3', 100, 'mobn3');
    }, 1800);
}



/********************************************************************
 * OTHER SCRIPTS
*********************************************************************/
// Owl
$('.owl-carousel').owlCarousel({
    loop: true,
    margin: 0,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsive: {
        0: {
            items: 1
        }
    }
})

//  AddClass on PageLoad
jQuery(window).ready(function() {
    setInterval(function() {
        $('body').addClass("page-load")
    }, 3000);

});      

// Footer Pop Out
function openPopUpUrl(e,t,a){return window.open(e,"","height="+t+", width="+a+", scrollbars=yes, resizable=yes");}